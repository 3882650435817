<template>
    <Sidebar v-model:visible="show" @hide="closeHelp" :baseZIndex="1000" position="full">
        <h3>Module Help</h3>
    </Sidebar>
</template>
<script>
export default {
    data(){
        return{
            show:true
        }
    },
    props:{
        moduleId:{
            type: Number
        } 
    }, 
    mounted() {  
        // alert(this.moduleId);
    },
    methods:{
        closeHelp(){ 
            this.$emit('closeHelp',true); 
        }
    }  
}
</script>