<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="false" @hide="closeForm" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{ module_name }}</h3>
                        <p>You can add or update {{ module_name }} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Company Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name" type="text"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="name" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>Sort Order</label>
                                    <InputText id="sort_order" placeholder="Sort Order" v-model="form.sort_order" min="0"
                                        step="1" type="number" class="full-width" />
                                    <HasError class="p-error" :form="form" field="sort_order" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" v-if="!addCustomer">
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name"
                                        optionValue="id" placeholder="Select Status" class="full-width" />
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <Panel v-if="companyLogo" class="mb-3" header="Company Logo" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2 profileCardImg">
                                        <div>
                                            <img alt="Company Logo" :src="companyLogo">
                                        </div>
                                    </div>
                                </Panel>
                                <Panel class="mb-3" header="Company Logo" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                        <FileUpload name="client_company_logo" :showUploadButton="false"
                                            :customUpload="true" @select="myUploader" :multiple="false" accept="image/*"
                                            :maxFileSize="1000000">
                                        </FileUpload>
                                    </div>
                                </Panel>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check"
                                    @click="saveAndClose = true, save()"
                                    class="p-button-raised p-button-lg p-button-info mr-2" />
                                <Button type="button" label="Reset" icon="pi pi-refresh"
                                    class="p-right p-button-raised p-button-lg p-button-warning mr-2"
                                    @click="resetForm()" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>

    </BlockUI>
</template>
<script>
export default {
    data() {
        return {
            form: new this.Form({
                id: '',
                name: '',
                client_company_logo: '',
                sort_order: 0,
                status: 1,

            }),
            // selectAllAL:false,
            module_name: 'Client Companies',
            moduleId: 69,
            companies: [],
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
        }
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
    },
    components: {
    },
    created() {
        this.modulePermissions = this.permissions[this.moduleId];
    },
    computed: {
    },
    mounted() {
        this.resetForm();
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.getAllRequestData();
    },
    methods: {

        getAllRequestData() {
            this.$axios.all([this.getCompanies()])
                .then(() => {
                    this.updateRecord();
                })
                .then(() => {
                    this.blockComponent = false;
                }).catch(() => {
                    //    this.getAllRequestData();
                })
                .finally(() => {
                });
        },

        selectCompany(e) {
            var company = e.value;
        },
        async getCompanies() {
            var Vthis = this;
            if (!this.form.company_id) {
                this.form.company_id = this.userData['company_id'];
            }
            await Vthis.axios.post('getAllCompanies', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.companies = response.data;
                        if (this.form.company_id == 0) {
                            this.form.company_id = response.data[0].id;
                        }


                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        resetForm() {
            this.form.reset();
        },
        async save() {
            var Vthis = this;
            this.isSaving = true;
            await this.form
                .post(Vthis.$baseurl + 'api/saveClientCompany', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        Vthis.showMessage('Saved successfully', 'success');
                        if (!this.recId) {
                            this.resetForm();
                        }
                        // this.getRecords();
                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                            setTimeout(() => { this.closeForm(); }, 1000);
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                })
        },
        loadForm() {
            this.resetForm();
        },
        closeForm() {
            this.$emit('formClose', true);
        },
        myUploader(event) {
            this.form.client_company_logo = event.files;
        },
        async updateRecord() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios.post('getAllClientCompanies', { id: this.recId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]);
                            Vthis.companyLogo = false;
                            if (response.data[0].client_company_logo) {
                                Vthis.companyLogo = Vthis.filesUrl + "client_companies/" + response.data[0].client_company_logo;
                            }
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    })
            }
        },
        confirmFileDeletion($event) {
            this.$confirm.require({
                target: $event.currentTarget,
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.removecompanyLogo();
                },
                reject: () => {

                }
            });
        },
    },
    watch: {
    }
}
</script> 
